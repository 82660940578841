/* eslint-disable max-lines-per-function */
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { editAddressesFeatures, editBuildingAddressesFeatures } from '@services/buildingService';
import { IBuildingAddress, IFeature } from '@types';
import { buildingsWithAddressesAtom, locationsAtom } from '@store';
import { LocationTypeEn } from '@constants';
import { SectionLabel } from '../common/section';
import CypressIds from '../../../cypressIds';

const EditApartmentLocationLabel = styled(SectionLabel)(({ theme }) => ({
  color: theme.palette.neutral.main,
  padding: '8px 0',
  fontSize: theme.typography.pxToRem(14)
}));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const optionsWithGeen = (options: any[]) => [
  ...options,
  {
    id: 'geen',
    title: 'Geen'
  }
];

export const EditApartmentLocation = (props: {
  onClose: (isSuccess?: boolean) => void;
  selectedItems: IBuildingAddress[];
  locations: IFeature[];
}) => {
  const { onClose, selectedItems, locations } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { preselectedParkings, preselectedEntrances, preselectedGates } = locations.reduce(
    (acc, location) => {
      const isLocationSelected = selectedItems.find((item) =>
        item.featureIds.includes(location.id)
      );
      if (isLocationSelected) {
        if (location.type === LocationTypeEn.Parking) {
          acc.preselectedParkings.push(location);
        }

        if (location.type === LocationTypeEn.Poort) {
          acc.preselectedGates.push(location);
        }

        if (location.type === LocationTypeEn.Voordeur) {
          acc.preselectedEntrances.push(location);
        }
      }

      return acc;
    },
    {
      preselectedParkings: [],
      preselectedEntrances: [],
      preselectedGates: []
    }
  );

  const { parkings, entrances, gates } = locations.reduce(
    (acc, location) => {
      if (location.type === LocationTypeEn.Parking) {
        acc.parkings.push(location);
      }
      if (location.type === LocationTypeEn.Voordeur) {
        acc.entrances.push(location);
      }
      if (location.type === LocationTypeEn.Poort) {
        acc.gates.push(location);
      }
      return acc;
    },
    {
      parkings: [],
      entrances: [],
      gates: []
    }
  );

  const selectedItemsWithParking = selectedItems?.filter((item) =>
    parkings?.find(
      (location) =>
        location?.type === LocationTypeEn.Parking && item.featureIds.includes(location.id)
    )
  );

  const selectedItemsWithEntrances = selectedItems?.filter((item) =>
    entrances?.find(
      (location) =>
        location?.type === LocationTypeEn.Voordeur && item.featureIds.includes(location.id)
    )
  );

  const selectedItemsWithGates = selectedItems?.filter((item) =>
    gates?.find(
      (location) => location?.type === LocationTypeEn.Poort && item.featureIds.includes(location.id)
    )
  );

  const preselectedParking =
    preselectedParkings.length === 1 && selectedItemsWithParking.length === selectedItems.length
      ? preselectedParkings[0]
      : null;
  const preselectedEntrance =
    preselectedEntrances.length === 1 && selectedItemsWithEntrances.length === selectedItems.length
      ? preselectedEntrances[0]
      : null;
  const preselectedGate =
    preselectedGates.length === 1 && selectedItemsWithGates.length === selectedItems.length
      ? preselectedGates[0]
      : null;

  const [data, setData] = useState({
    entranceId: preselectedEntrance?.id || undefined,
    parkingId: preselectedParking?.id || undefined,
    gateId: preselectedGate?.id || undefined
  });

  const handleSave = () => {
    const addressIds = selectedItems.map(({ id }) => {
      return id;
    });
    const { entranceId, parkingId, gateId } = data;
    setIsSubmitting(true);
    editAddressesFeatures({
      addressIds,
      features: {
        ...(parkingId && {
          [`${LocationTypeEn.Parking}`]: parkingId == 'geen' ? null : parkingId
        }),
        ...(entranceId && {
          [`${LocationTypeEn.Voordeur}`]: entranceId == 'geen' ? null : entranceId
        }),
        ...(gateId && {
          [`${LocationTypeEn.Poort}`]: gateId == 'geen' ? null : gateId
        })
      }
    }).finally(() => {
      setIsSubmitting(false);
      onClose(true);
    });
  };

  return (
    <Dialog open>
      <DialogTitle>
        <Typography variant='h6' component='p' pt={1}>
          {selectedItems.length} adressen bewerken
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography variant='body3' component='p'>
            Selecteer hieronder een bijbehorende parking, voordeur en/of poort locatie voor de
            geselecteerde adres(sen).
          </Typography>
          <Grid display='flex' flexDirection='column' gap={2} py={2}>
            <FormControl>
              <EditApartmentLocationLabel>Parking locatie</EditApartmentLocationLabel>
              <Autocomplete
                disableClearable
                defaultValue={preselectedParking}
                onChange={(e, option) => {
                  setData((previousState) => ({
                    ...previousState,
                    parkingId: option?.id ?? null
                  }));
                }}
                options={optionsWithGeen(parkings)}
                noOptionsText='Geen opties'
                renderInput={(params) => (
                  <TextField
                    data-cy={CypressIds.SELECT_PARKING}
                    {...params}
                    placeholder='Selecteer locatie'
                  />
                )}
                getOptionLabel={(option) => {
                  return option?.title;
                }}
              />
            </FormControl>
            <FormControl>
              <EditApartmentLocationLabel>Voordeur locatie</EditApartmentLocationLabel>
              <Autocomplete
                defaultValue={preselectedEntrance}
                onChange={(e, option) =>
                  setData((previousState) => ({
                    ...previousState,
                    entranceId: option?.id ?? null
                  }))
                }
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                options={optionsWithGeen(entrances)}
                noOptionsText='Geen opties'
                renderInput={(params) => (
                  <TextField
                    data-cy={CypressIds.SELECT_ENTRANCE}
                    {...params}
                    placeholder='Selecteer locatie'
                  />
                )}
                getOptionLabel={(option) => {
                  return option?.title;
                }}
              />
            </FormControl>
            <FormControl>
              <EditApartmentLocationLabel>Poort locatie</EditApartmentLocationLabel>
              <Autocomplete
                defaultValue={preselectedGate}
                onChange={(e, option) =>
                  setData((previousState) => ({
                    ...previousState,
                    gateId: option?.id ?? null
                  }))
                }
                options={optionsWithGeen(gates)}
                noOptionsText='Geen opties'
                renderInput={(params) => (
                  <TextField
                    data-cy={CypressIds.SELECT_GATE}
                    {...params}
                    placeholder='Selecteer locatie'
                  />
                )}
                getOptionLabel={(option) => {
                  
                    return option.title;
                  
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          py: 1.5
        }}
      >
        <Button variant='text' color='neutral' onClick={() => onClose()}>
          Annuleren
        </Button>

        <LoadingButton
          variant='contained'
          onClick={handleSave}
          loading={isSubmitting}
          color='primary'
          data-cy={CypressIds.COMMON_CONFIRM_BUTTON}
        >
          Opslaan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const BatchEditApartmentLocation = (props: {
  onClose: (isSuccess?: boolean) => void;
  selectedBuildings: string[];
}) => {
  const { onClose, selectedBuildings } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [locations] = useAtom(locationsAtom);
  const [buildingsWithAddresses] = useAtom(buildingsWithAddressesAtom);

  const selectedItems = buildingsWithAddresses
    ?.filter((item) => {
      return selectedBuildings?.includes(`${item.buildingId}`);
    })
    .map((item) => item.addresses)
    .flat();

  const { preselectedParkings, preselectedEntrances, preselectedGates } = locations.reduce(
    (acc, location) => {
      const isLocationSelected = selectedItems?.find((item) =>
        item.featureIds.includes(+location.id)
      );
      if (isLocationSelected) {
        if (location.type === LocationTypeEn.Parking) {
          acc.preselectedParkings.push(location);
        }

        if (location.type === LocationTypeEn.Poort) {
          acc.preselectedGates.push(location);
        }

        if (location.type === LocationTypeEn.Voordeur) {
          acc.preselectedEntrances.push(location);
        }
      }

      return acc;
    },
    {
      preselectedParkings: [],
      preselectedEntrances: [],
      preselectedGates: []
    }
  );

  const { parkings, entrances, gates } = locations.reduce(
    (acc, location) => {
      if (location.type === LocationTypeEn.Parking) {
        acc.parkings.push(location);
      }
      if (location.type === LocationTypeEn.Voordeur) {
        acc.entrances.push(location);
      }
      if (location.type === LocationTypeEn.Poort) {
        acc.gates.push(location);
      }
      return acc;
    },
    {
      parkings: [],
      entrances: [],
      gates: []
    }
  );

  const selectedItemsWithParking = selectedItems?.filter((item) =>
    parkings?.find(
      (location) =>
        location?.type === LocationTypeEn.Parking && item.featureIds.includes(+location.id)
    )
  );

  const selectedItemsWithEntrances = selectedItems?.filter((item) =>
    entrances?.find(
      (location) =>
        location?.type === LocationTypeEn.Voordeur && item.featureIds.includes(+location.id)
    )
  );

  const selectedItemsWithGates = selectedItems?.filter((item) =>
    gates?.find(
      (location) =>
        location?.type === LocationTypeEn.Poort && item.featureIds.includes(+location.id)
    )
  );

  const preselectedParking =
    preselectedParkings.length === 1 && selectedItemsWithParking.length === selectedItems.length
      ? preselectedParkings[0]
      : null;
  const preselectedEntrance =
    preselectedEntrances.length === 1 && selectedItemsWithEntrances.length === selectedItems.length
      ? preselectedEntrances[0]
      : null;
  const preselectedGate =
    preselectedGates.length === 1 && selectedItemsWithGates.length === selectedItems.length
      ? preselectedGates[0]
      : null;

  const [data, setData] = useState({
    entranceId: preselectedEntrance?.id || undefined,
    parkingId: preselectedParking?.id || undefined,
    gateId: preselectedGate?.id || undefined
  });

  const handleSave = () => {
    const { entranceId, parkingId, gateId } = data;
    setIsSubmitting(true);
    editBuildingAddressesFeatures({
      buildingIds: selectedBuildings,
      features: {
        ...(parkingId && {
          [`${LocationTypeEn.Parking}`]: parkingId == 'geen' ? null : parkingId
        }),
        ...(entranceId && {
          [`${LocationTypeEn.Voordeur}`]: entranceId == 'geen' ? null : entranceId
        }),
        ...(gateId && {
          [`${LocationTypeEn.Poort}`]: gateId == 'geen' ? null : gateId
        })
      }
    }).finally(() => {
      setIsSubmitting(false);
      onClose(true);
    });
  };
  return (
    <Dialog open>
      <DialogTitle>
        <Typography variant='h6' component='p' pt={1}>
          {selectedItems?.length} adressen bewerken
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography variant='body3' component='p'>
            Selecteer hieronder een bijbehorende parking, voordeur en/of poort locatie voor de
            geselecteerde adres(sen).
          </Typography>
          <Grid display='flex' flexDirection='column' gap={2} py={2}>
            <FormControl>
              <EditApartmentLocationLabel>Parking locatie</EditApartmentLocationLabel>
              <Autocomplete
                disableClearable
                defaultValue={preselectedParking}
                onChange={(e, option) => {
                  setData((previousState) => ({
                    ...previousState,
                    parkingId: option?.id ?? null
                  }));
                }}
                options={optionsWithGeen(parkings)}
                noOptionsText='Geen opties'
                renderInput={(params) => (
                  <TextField
                    data-cy={CypressIds.SELECT_PARKING}
                    {...params}
                    placeholder='Selecteer locatie'
                  />
                )}
                getOptionLabel={(option) => {
                  return option?.title;
                }}
              />
            </FormControl>
            <FormControl>
              <EditApartmentLocationLabel>Voordeur locatie</EditApartmentLocationLabel>
              <Autocomplete
                defaultValue={preselectedEntrance}
                onChange={(e, option) =>
                  setData((previousState) => ({
                    ...previousState,
                    entranceId: option?.id ?? null
                  }))
                }
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                options={optionsWithGeen(entrances)}
                noOptionsText='Geen opties'
                renderInput={(params) => (
                  <TextField
                    data-cy={CypressIds.SELECT_ENTRANCE}
                    {...params}
                    placeholder='Selecteer locatie'
                  />
                )}
                getOptionLabel={(option) => {
             
                    return option.title;
                  
                }}
              />
            </FormControl>
            <FormControl>
              <EditApartmentLocationLabel>Poort locatie</EditApartmentLocationLabel>
              <Autocomplete
                defaultValue={preselectedGate}
                onChange={(e, option) =>
                  setData((previousState) => ({
                    ...previousState,
                    gateId: option?.id ?? null
                  }))
                }
                options={optionsWithGeen(gates)}
                noOptionsText='Geen opties'
                renderInput={(params) => (
                  <TextField
                    data-cy={CypressIds.SELECT_GATE}
                    {...params}
                    placeholder='Selecteer locatie'
                  />
                )}
                getOptionLabel={(option) => {
                  if (option?.title) {
                    return option.title;
                  }

                  return option?.properties?.name;
                }}
              />
            </FormControl>
          </Grid>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          py: 1.5
        }}
      >
        <Button variant='text' color='neutral' onClick={() => onClose()}>
          Annuleren
        </Button>

        <LoadingButton
          variant='contained'
          onClick={handleSave}
          loading={isSubmitting}
          color='primary'
          data-cy={CypressIds.COMMON_CONFIRM_BUTTON}
        >
          Opslaan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BatchEditApartmentLocation;
