/* eslint-disable import/prefer-default-export */
import BatchEditApartmentLocation from '@components/EditApartmentLocation';
import { Edit } from '@mui/icons-material';
import { Button } from '@mui/material';
import { selectedBuildingsAtom } from '@store';
import CypressIds from '../../../cypressIds';

export const EditLocationsButton = ({ onSuccess }: { onSuccess: () => void }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedBuildings] = useAtom(selectedBuildingsAtom);

  const onEditLocation = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <>
      <Button
        data-cy={CypressIds.EDIT_FEATURES_BUTTON}
        startIcon={<Edit />}
        variant='outlined'
        onClick={onEditLocation}
      >
        Locaties bewerken
      </Button>
      {isOpen && (
        <BatchEditApartmentLocation
          selectedBuildings={selectedBuildings as string[]}
          onClose={() => {
            setIsOpen(false);
            onSuccess();
          }}
        />
      )}
    </>
  );
};
