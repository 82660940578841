/* eslint-disable max-lines-per-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import {
  Accordion,
  AccordionDetails,
  Box,
  Grid,
  useTheme,
  AccordionSummary,
  Divider
} from '@mui/material';
import {
  Delete,
  DoorFrontOutlined,
  Edit,
  ExpandMore,
  Fence,
  LocalParking
} from '@mui/icons-material';
import { useAtom } from 'jotai';
import { v4 } from 'uuid';
import { AddLocationButton } from '@components/common/AddLocationBtn';
import MapContext from 'contexts/MapContext';
import { getLocations, removeLocation } from '@services/buildingService';
import { getMapBbox, getMarkerColorByType, hoverOverLocations, jumpToLocation } from '@utils/map';
import CircularLoading from '@components/ui-kit/CircularLoading';
import { SectionHeader } from '@components/common/section';
import NotFoundText from '@components/styled/not-found-text';
import AdvancedIconButton from '@components/advanced-icon-button';
import { COMMON_ERROR_MESSAGE, LocationType } from '@constants';
import DeleteModal from '@components/delete-modal';
import { buildingAddressesAtom, isLocationsLoadingAtom, locationsAtom } from '@store';
import { IFeature } from '@types';
import { Map } from 'maplibre-gl';
import { debounce } from 'lodash-es';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import { convertType } from '@helpers/formatLocations';
import useSnackbar from '@hooks/use-snackbar';
import useChangeRouterSearchParams from '@hooks/useChangeRouterSearchParams';
import CypressIds from '../../cypressIds';
import BoundedText from './bounded-text';

const LocationIcons = {
  [LocationType.Poort]: Fence,
  [LocationType.Parking]: LocalParking,
  [LocationType.Voordeur]: DoorFrontOutlined
};

function LocationItemView({
  location,
  onRemove,
  onEdit,
  onHover,
  onClick
}: {
  location: IFeature;
  onRemove: (location: IFeature) => void;
  onEdit: (location: IFeature) => void;
  onHover: (location: IFeature | null) => void;
  onClick: (location: IFeature) => void;
}) {
  const theme = useTheme();
  const { title } = location;
  const type = convertType(location.type as LocationType) as LocationType;
  const icon = LocationIcons[type];

  return (
    <Grid
      container
      alignItems='center'
      justifyContent='space-between'
      onMouseEnter={() => onHover(location)}
      onMouseLeave={() => onHover(null)}
      onClick={() => onClick(location)}
      sx={{
        py: 1,
        px: 3,
        cursor: 'pointer',
        '&:hover': {
          background: theme.palette.neutral['900-8']
        }
      }}
    >
      <Grid
        item
        display='flex'
        alignItems='center'
        sx={{
          maxWidth: 300,
          minWidth: 200
        }}
      >
        <Box
          sx={{
            fontSize: theme.typography.pxToRem(24),
            color: getMarkerColorByType(type)
          }}
          component={icon}
        />

        <BoundedText
          initialWidth={210}
          text={title}
          textSx={{
            width: 220,
            pl: 1,
            pt: 0.33
          }}
        >
          {title}
        </BoundedText>
      </Grid>
      <Grid item>
        <AdvancedIconButton
          tooltip='Locatie bewerken'
          icon={<Edit data-cy={`${CypressIds.EDIT_FEATURE_BUTTON}-${location.id}`} />}
          onClick={() => onEdit(location)}
        />
        <AdvancedIconButton
          tooltip='Locatie verwijderen'
          icon={<Delete data-cy={`${CypressIds.DELETE_FEATURE_BUTTON}-${location.title}`} />}
          onClick={(e: { stopPropagation: () => void }) => {
            e.stopPropagation();
            onRemove(location);
          }}
        />
      </Grid>
    </Grid>
  );
}

const LocationsSection = ({ isVisible }: { isVisible: boolean }) => {
  const { map } = useContext(MapContext);
  const [locations, setLocations] = useAtom(locationsAtom);
  const [isLocationsLoading, setIsLocationsLoading] = useAtom(isLocationsLoadingAtom);
  const [refetchHash, setRefetchHash] = useState<
    string | null | ((currentValue: string | null) => string | null)
  >(null);
  const { buildingId } = useParams();
  const [deletingItemId, setDeletingItemId] = useState('');
  const debouncedHoverOverLocations = useRef(debounce(hoverOverLocations, 1000)).current;
  const [_, setAddresses] = useAtom(buildingAddressesAtom);
  const snackbar = useSnackbar();
  const navigate = useAdvancedNavigate();

  useEffect(() => {
    if (!map) {
      return;
    }
    if (refetchHash) {
      setIsLocationsLoading(true);
      const bbox = getMapBbox(map as Map);
      getLocations(bbox)
        .then((features ) => {
          setLocations(features);
        })
        .finally(() => {
          setIsLocationsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchHash]);

  const isDeleteModalOpen = deletingItemId !== '';

  const handleRemoveClick = (location: IFeature) => {
    if (!isDeleteModalOpen) {
      setDeletingItemId(location.id);
    } else {
      setDeletingItemId('');
      removeLocation(deletingItemId)
        .then(() => {
          document.querySelectorAll('.building-location-marker').forEach((marker) => {
            if (marker.getAttribute('data-location-id') == deletingItemId) {
              marker.remove();
            }
          });
          // If the location is removed from the building, we need to refetch the addresses
          if (buildingId) {
            setAddresses(null);
          }
          snackbar.show('Locatie succesvol verwijderd.');
          setRefetchHash(v4() as string);
        })
        .catch(() => {
          snackbar.error(COMMON_ERROR_MESSAGE);
        });
    }
  };

  const handleEditClick = (location: IFeature) => {
    jumpToLocation(location, map);
    navigate(`/buildings/locations/${location.id}/update/`);
  };

  useEffect(() => {
    return () => {
      debouncedHoverOverLocations.cancel();
    };
  }, [debouncedHoverOverLocations]);

  const locationsCount = locations?.length;

  return (
    <Accordion hidden={!isVisible} defaultExpanded>
      <AccordionSummary
        sx={{
          '.Mui-expanded': {
            margin: '12px 0 !important'
          }
        }}
        expandIcon={<ExpandMore />}
      >
        <Grid
          container
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <SectionHeader px={1}>Locaties ({locationsCount ?? 0})</SectionHeader>
          <AddLocationButton
            sx={{
              mr: 3
            }}
          />
        </Grid>
      </AccordionSummary>
      <Divider />
      <AccordionDetails
        sx={{
          maxHeight: 448,
          overflowY: 'auto',
          padding: '0px 0px 12px'
        }}
        onMouseLeave={() => {
          debouncedHoverOverLocations(null);
        }}
      >
        {isLocationsLoading ? (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2
            }}
          >
            <CircularLoading />
          </Grid>
        ) : (
          <Grid
            pt={1}
            sx={{
              overflowY: 'auto'
            }}
          >
            {locationsCount && locationsCount > 0 ? (
              locations?.map((location) => (
                <LocationItemView
                  key={location.id}
                  onHover={() => debouncedHoverOverLocations([location.id])}
                  onEdit={handleEditClick}
                  onRemove={handleRemoveClick}
                  onClick={() => {
                    jumpToLocation(location, map);
                  }}
                  location={location}
                />
              ))
            ) : (
              <NotFoundText textAlign='center' px={3}>
                Geen locaties om weer te geven
              </NotFoundText>
            )}
          </Grid>
        )}
      </AccordionDetails>
      {isDeleteModalOpen && (
        <DeleteModal onSubmit={handleRemoveClick} onClose={() => setDeletingItemId('')} />
      )}
    </Accordion>
  );
};

export default LocationsSection;
