/* eslint-disable import/extensions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable max-lines-per-function */
import maplibregl, {
  Feature,
  GeoJSONSource,
  LngLatLike,
  Map,
  Marker,
  MarkerOptions
} from 'maplibre-gl';
import {
  ADDRESS_MARKER_COLOR,
  ADDRESS_HOVERED_ITEM_COLOR,
  APP_MAPBOX_MAPS_API_KEY,
  ENTRANCE_COLOR,
  GATE_COLOR,
  ITEM_COLORS,
  LocationType,
  LocationTypeEn,
  MAP_SNAP_TO_ROAD_COLOR,
  PARKING_COLOR,
  TEMPORARY_MARKER_COLOR,
  BUILDING_SELECT_COLOR
} from '@constants';
import {
  IBuilding,
  IBuildingAddress,
  IBuildingAddressesData,
  ICoordinate,
  IHub,
  IFeature
} from '@types';

import { formatAddressHouseNumber } from '@helpers/formatAddress';
import { convertType } from '@helpers/formatLocations';
import { REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS } from 'constants/map';
import { MapSourceName } from './data';
import { isMapboxURL, transformMapboxUrl } from './urlRequestTransformer';
import { ExtendedMarkerOptions } from '../../types/map.d';
import { BUILDING_COLOR } from '../../constants/colors';

const FEATURE_STATE = 'feature-state';

export enum BuildingState {
  MultiSelect = 'multiselect',
  Select = 'select',
  Default = 'default'
}

const setBuildingState = ({
  map,
  buildingIds,
  id,
  property,
  state
}: {
  map: Map;
  buildingIds: string[] | number[];
  id: string | number;
  property: string;
  state: boolean;
}) => {
  const buildingsSource = map?.getSource('buildings');
  if (!buildingsSource) {
    /* eslint-disable-next-line no-console */
    console.warn('setBuildingState : data store is null');
    return;
  }

  if (buildingIds !== null) {
    // eslint-disable-next-line eqeqeq
    const filteredBuildings = buildingIds?.filter((buildingId) => buildingId == id);
    if (!filteredBuildings || filteredBuildings.length === 0) {
      return;
    }

    map.setFeatureState(
      { source: 'buildings', id: filteredBuildings[0] },
      { [`${property}`]: state }
    );
  } else {
    map.setFeatureState({ source: 'buildings', id }, { [`${property}`]: state });
  }
};

function clearAllMarkers() {
  document.querySelectorAll('.building-location-marker').forEach((tag) => tag.remove());
  document.querySelectorAll('.search-marker').forEach((tag) => tag.remove());
}

const clearAllSearchMarkers = () => {
  document.querySelectorAll('.search-marker').forEach((tag) => tag.remove());
};

export const MARKER_TYPE = {
  Parking: 'Parking',
  Voordeur: 'Voordeur', // Entrance
  Poort: 'Poort', // Gate
  SnapToRoad: 'SnapToRoad',
  Depot: 'Depot',
  Address: 'Address'
};

const getMarkerElement = (type: string, color?: string): HTMLElement | null => {
  const element = document.createElement('div');
  let content = '';
  switch (type) {
    case MARKER_TYPE.Poort:
      content = `<svg width="30" height="47" viewBox="0 0 30 47" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0003 1.3335C8.00033 1.3335 1.66699 6.70016 1.66699 15.0002C1.66699 20.5335 6.11699 27.0835 15.0003 34.6668C23.8837 27.0835 28.3337 20.5335 28.3337 15.0002C28.3337 6.70016 22.0003 1.3335 15.0003 1.3335Z" fill="white"/>
                  <path d="M14.6757 35.0471L15.0003 35.3242L15.325 35.0471C19.7861 31.2388 23.1551 27.6726 25.412 24.3454C27.6678 21.0198 28.8337 17.9016 28.8337 15.0002C28.8337 6.40872 22.2609 0.833496 15.0003 0.833496C7.73974 0.833496 1.16699 6.40872 1.16699 15.0002C1.16699 17.9016 2.33281 21.0198 4.58862 24.3454C6.84554 27.6726 10.2146 31.2388 14.6757 35.0471Z" stroke="#202124" stroke-opacity="0.16"/>
      <path d="M2.16699 15.0002C2.16699 6.99161 8.26091 1.8335 15.0003 1.8335C21.7397 1.8335 27.8337 6.99161 27.8337 15.0002C27.8337 17.6321 26.7745 20.5555 24.5845 23.7841C22.4494 26.9316 19.2596 30.3393 15.0003 34.0082C10.741 30.3393 7.55121 26.9316 5.41619 23.7841C3.22618 20.5555 2.16699 17.6321 2.16699 15.0002Z" fill="${color || 'currentColor'}" stroke="white"/>
      <path d="M10.3333 19.3334V16.6667H9V15.3334H10.3333V14.0001H9V12.6667H10.3333V10.6667L12.3333 8.66675L13.6667 10.0001L15.0167 8.66675L16.35 10.0001L17.6833 8.66675L19.6833 10.6667V12.6667H21V14.0001H19.6833V15.3334H21V16.6667H19.6833V19.3334H10.3333ZM11.6667 12.6667H13V11.2167L12.3333 10.5501L11.6667 11.2167V12.6667ZM14.3333 12.6667H15.6667V11.2167L15 10.5501L14.3333 11.2167V12.6667ZM17.0167 12.6667H18.3333V11.2167L17.6667 10.5501L17.0167 11.2001V12.6667ZM11.6667 15.3334H13V14.0001H11.6667V15.3334ZM14.3333 15.3334H15.6667V14.0001H14.3333V15.3334ZM17.0167 15.3334H18.3333V14.0001H17.0167V15.3334ZM11.6667 18.0001H13V16.6667H11.6667V18.0001ZM14.3333 18.0001H15.6667V16.6667H14.3333V18.0001ZM17.0167 18.0001H18.3333V16.6667H17.0167V18.0001Z" fill="white"/>
      <rect x="10" y="37" width="10" height="10" rx="5" fill="#202124" fill-opacity="0.16"/>
      <rect x="11" y="38" width="8" height="8" rx="4" fill="white"/>
      <rect x="11.5" y="38.5" width="7" height="7" rx="3.5" fill="${color || 'currentColor'}" stroke="white"/>
        </svg>
        `;
      break;
    case MARKER_TYPE.Parking:
      content = `<svg width="30" height="47" viewBox="0 0 30 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0003 1.3335C8.00033 1.3335 1.66699 6.70016 1.66699 15.0002C1.66699 20.5335 6.11699 27.0835 15.0003 34.6668C23.8837 27.0835 28.3337 20.5335 28.3337 15.0002C28.3337 6.70016 22.0003 1.3335 15.0003 1.3335Z" fill="white"/>
        <path d="M14.6757 35.0471L15.0003 35.3242L15.325 35.0471C19.7861 31.2388 23.1551 27.6726 25.412 24.3454C27.6678 21.0198 28.8337 17.9016 28.8337 15.0002C28.8337 6.40872 22.2609 0.833496 15.0003 0.833496C7.73974 0.833496 1.16699 6.40872 1.16699 15.0002C1.16699 17.9016 2.33281 21.0198 4.58862 24.3454C6.84554 27.6726 10.2146 31.2388 14.6757 35.0471Z" stroke="#202124" stroke-opacity="0.16"/>
        <path d="M2.16699 15.0002C2.16699 6.99161 8.26091 1.8335 15.0003 1.8335C21.7397 1.8335 27.8337 6.99161 27.8337 15.0002C27.8337 17.6321 26.7745 20.5555 24.5845 23.7841C22.4494 26.9316 19.2596 30.3393 15.0003 34.0082C10.741 30.3393 7.55121 26.9316 5.41619 23.7841C3.22618 20.5555 2.16699 17.6321 2.16699 15.0002Z" fill="${color || 'currentColor'}" stroke="white"/>
        <path d="M11 20V8H15.6667C16.7778 8 17.7222 8.38889 18.5 9.16667C19.2778 9.94444 19.6667 10.8889 19.6667 12C19.6667 13.1111 19.2778 14.0556 18.5 14.8333C17.7222 15.6111 16.7778 16 15.6667 16H13.6667V20H11ZM13.6667 13.3333H15.8C16.1667 13.3333 16.4806 13.2028 16.7417 12.9417C17.0028 12.6806 17.1333 12.3667 17.1333 12C17.1333 11.6333 17.0028 11.3194 16.7417 11.0583C16.4806 10.7972 16.1667 10.6667 15.8 10.6667H13.6667V13.3333Z" fill="white"/>
        <rect x="10" y="37" width="10" height="10" rx="5" fill="#202124" fill-opacity="0.16"/>
        <rect x="11" y="38" width="8" height="8" rx="4" fill="white"/>
        <rect x="11.5" y="38.5" width="7" height="7" rx="3.5" fill="${color || 'currentColor'}" stroke="white"/>
    </svg>

      `;
      break;
    case MARKER_TYPE.Voordeur:
      content = `
      <svg width="30" height="47" viewBox="0 0 30 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.0003 1.3335C8.00033 1.3335 1.66699 6.70016 1.66699 15.0002C1.66699 20.5335 6.11699 27.0835 15.0003 34.6668C23.8837 27.0835 28.3337 20.5335 28.3337 15.0002C28.3337 6.70016 22.0003 1.3335 15.0003 1.3335Z" fill="#F9F9F9"/>
      <path d="M14.6757 35.0471L15.0003 35.3242L15.325 35.0471C19.7861 31.2388 23.1551 27.6726 25.412 24.3454C27.6678 21.0198 28.8337 17.9016 28.8337 15.0002C28.8337 6.40872 22.2609 0.833496 15.0003 0.833496C7.73974 0.833496 1.16699 6.40872 1.16699 15.0002C1.16699 17.9016 2.33281 21.0198 4.58862 24.3454C6.84554 27.6726 10.2146 31.2388 14.6757 35.0471Z" stroke="#202124" stroke-opacity="0.16"/>
        <path d="M2.16699 15.0002C2.16699 6.99161 8.26091 1.8335 15.0003 1.8335C21.7397 1.8335 27.8337 6.99161 27.8337 15.0002C27.8337 17.6321 26.7745 20.5555 24.5845 23.7841C22.4494 26.9316 19.2596 30.3393 15.0003 34.0082C10.741 30.3393 7.55121 26.9316 5.41619 23.7841C3.22618 20.5555 2.16699 17.6321 2.16699 15.0002Z" fill="#F9F9F9" stroke="${color || 'currentColor'}"/>
                <path d="M9 20V18.6667H10.3333V9.33333C10.3333 8.96667 10.4639 8.65278 10.725 8.39167C10.9861 8.13056 11.3 8 11.6667 8H18.3333C18.7 8 19.0139 8.13056 19.275 8.39167C19.5361 8.65278 19.6667 8.96667 19.6667 9.33333V18.6667H21V20H9ZM18.3333 18.6667V9.33333H11.6667V18.6667H18.3333ZM16.3333 14.6667C16.5222 14.6667 16.6806 14.6028 16.8083 14.475C16.9361 14.3472 17 14.1889 17 14C17 13.8111 16.9361 13.6528 16.8083 13.525C16.6806 13.3972 16.5222 13.3333 16.3333 13.3333C16.1444 13.3333 15.9861 13.3972 15.8583 13.525C15.7306 13.6528 15.6667 13.8111 15.6667 14C15.6667 14.1889 15.7306 14.3472 15.8583 14.475C15.9861 14.6028 16.1444 14.6667 16.3333 14.6667Z" fill="${color || 'currentColor'}"/>
                <rect x="10" y="37" width="10" height="10" rx="5" fill="${color || 'currentColor'}"/>
        <rect x="11" y="38" width="8" height="8" rx="4" fill="#F9F9F9"/>
        <rect x="11.5" y="38.5" width="7" height="7" rx="3.5" fill="#F9F9F9" stroke="#F9F9F9"/>
      </svg>

      `;
      break;
    case MARKER_TYPE.Depot:
      content = `<svg width="34" height="54" viewBox="0 0 34 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 1C8.6 1 1 7.44 1 17.4C1 24.04 6.34 31.9 17 41C27.66 31.9 33 24.04 33 17.4C33 7.44 25.4 1 17 1Z" fill="white"/>
      <path d="M16.6754 41.3803L17 41.6574L17.3246 41.3803C22.6741 36.8137 26.7098 32.5408 29.4113 28.5582C32.1117 24.5772 33.5 20.8548 33.5 17.4C33.5 7.14856 25.6606 0.5 17 0.5C8.33941 0.5 0.5 7.14856 0.5 17.4C0.5 20.8548 1.88832 24.5772 4.58871 28.5582C7.29022 32.5408 11.3259 36.8137 16.6754 41.3803Z" stroke="#202124" stroke-opacity="0.16"/>
      <path d="M1.5 17.4C1.5 7.73144 8.86059 1.5 17 1.5C25.1394 1.5 32.5 7.73144 32.5 17.4C32.5 20.5852 31.2183 24.1128 28.5837 27.9968C26.0042 31.7997 22.1478 35.9141 17 40.3416C11.8522 35.9141 7.99584 31.7997 5.41629 27.9968C2.78168 24.1128 1.5 20.5852 1.5 17.4Z" fill="#FECC00" stroke="white"/>
      <path d="M17 29C10.376 29 5 23.624 5 17C5 10.376 10.376 5 17 5C23.624 5 29 10.376 29 17C29 23.624 23.624 29 17 29Z" fill="white"/>
      <rect x="12" y="44" width="10" height="10" rx="5" fill="#202124" fill-opacity="0.16"/>
      <rect x="13" y="45" width="8" height="8" rx="4" fill="white"/>
      <rect x="13.5" y="45.5" width="7" height="7" rx="3.5" fill="#FECC00" stroke="white"/>
      <g clip-path="url(#clip0_82_4754)">
      <path d="M15.6667 21.6667V18.3333H18.3333V21.6667C18.3333 22.0333 18.6333 22.3333 19 22.3333H21C21.3667 22.3333 21.6667 22.0333 21.6667 21.6667V17H22.8C23.1067 17 23.2533 16.62 23.02 16.42L17.4467 11.4C17.1933 11.1733 16.8067 11.1733 16.5533 11.4L10.98 16.42C10.7533 16.62 10.8933 17 11.2 17H12.3333V21.6667C12.3333 22.0333 12.6333 22.3333 13 22.3333H15C15.3667 22.3333 15.6667 22.0333 15.6667 21.6667Z" fill="#D50029"/>
      </g>
      <defs>
      <clipPath id="clip0_82_4754">
      <rect width="16" height="16" fill="white" transform="translate(9 9)"/>
      </clipPath>
      </defs>
      </svg>`;
      element.innerHTML = content;
      element.style.width = '38px';
      element.style.height = '114px';
      return element;
    default:
      return null;
  }
  element.className = 'marker';
  element.innerHTML = content;
  // Set marker size
  element.style.width = '31px';
  element.style.height = '88px';
  return element;
};

export const clearAllAddressAndLocationMarkers = (map: Map) => {
  if (!map) return;

  [MapSourceName.SnapToRoadIcon, MapSourceName.AddressIcon].forEach((sourceName) => {
    const source = map?.getSource(sourceName);

    if (source) {
      map.removeLayer(sourceName);
      map.removeSource(sourceName);
    }
  });
};

const getLocationMarkers = () => {
  return document.querySelectorAll('[data-location-id]');
};

export const getMarkerColorByType = (type: LocationType) => {
  switch (type) {
    case LocationType.Parking:
      return PARKING_COLOR;
    case LocationType.Voordeur:
      return ENTRANCE_COLOR;
    case LocationType.Poort:
      return GATE_COLOR;
    default:
      return ITEM_COLORS[1 % ITEM_COLORS.length];
  }
};

export const getMarkerClassNameByType = (type: LocationType) => {
  switch (type) {
    case LocationType.Parking:
      return 'parking-icon';
    case LocationType.Voordeur:
      return 'entrance-icon';
    case LocationType.Poort:
      return 'gate-icon';
    default:
      return ITEM_COLORS[1 % ITEM_COLORS.length];
  }
};

export const jumpToLocation = (location: IFeature, map: Map) => {
  map?.jumpTo({
    center: location.point.coordinates,
    ...(map.getZoom() < REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS && {
      zoom: REQUIRED_MAP_ZOOM_LEVEL_FOR_FETCHING_MAP_ITEMS
    })
  });
};

function addLocationMarkers(
  markers: IFeature[],
  map: Map,
  editingLocationId?: number,
  onClickListener?: (location: IFeature) => void
) {
  const markerRefs: Marker[] = [];
  let markerRef;
  const alreadyRenderedLocations = getLocationMarkers();
  markers?.forEach((location) => {
    if (alreadyRenderedLocations.length > 0) {
      alreadyRenderedLocations.forEach((marker) => {
        marker?.remove();
      });
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    const type = (location.type ? convertType(location.type) : 'Unknown') as LocationType &
      LocationTypeEn;

    const element = getMarkerElement(type);
    const isEditingLocation = editingLocationId === location.id;
    const glMarker = new maplibregl.Marker({
      draggable: isEditingLocation,
      id: location.id,
      ...(element
        ? {
            element
          }
        : {
            color: ITEM_COLORS[1 % ITEM_COLORS.length]
          })
    } as ExtendedMarkerOptions)
      .setLngLat(location.point.coordinates as LngLatLike)
      .addTo(map);

    if (editingLocationId && !isEditingLocation) {
      glMarker.addClassName('not-hovered');
    }

    if (isEditingLocation) {
      markerRef = glMarker;
    }

    glMarker.addClassName('building-location-marker');
    glMarker.addClassName(getMarkerClassNameByType(type));
    glMarker.getElement().setAttribute('data-location-id', `${location.id}`);
    glMarker.getElement().setAttribute('data-location-name', `${location.title}`);
    if (onClickListener && !window.location.pathname.includes('create')) {
      glMarker.getElement().addEventListener('click', () => {
        onClickListener?.(location);
      });
    }

    markerRefs.push(glMarker);
  });

  if (markerRef) {
    return markerRef;
  }

  return markerRefs;
}

function addDepotMarkers(hub: IHub, map: Map) {
  if (!hub.point.coordinates) return;

  const markerRefs: Marker[] = [];
  const element = getMarkerElement(MARKER_TYPE.Depot);
  const glMarker = new maplibregl.Marker({
    draggable: false,
    id: `${hub.id}`,
    element
  } as MarkerOptions)
    .setLngLat(hub.point.coordinates)
    .addTo(map);

  glMarker.getElement().classList.add('depot-marker');
  glMarker.getElement().setAttribute('data-depot-id', `${hub.id}`);

  markerRefs.push(glMarker);

  return markerRefs;
}

const clearTemporaryMarkers = () => {
  document.querySelectorAll('.map-marker-marker').forEach((tag) => tag.remove());
};

export interface ITemporaryMarker {
  id: string | number;
  coordinates: ICoordinate;
  type: LocationType | string;
  color?: string;
}
// Used for highlighting building markers on hover
const addMapMarker = ({
  marker,
  map,
  element,
  color
}: {
  marker: ITemporaryMarker;
  map: Map;
  element?: HTMLElement;
  color?: string;
}) => {
  const glMarker = new maplibregl.Marker({
    draggable: false,
    id: marker.id,
    color: color || TEMPORARY_MARKER_COLOR,
    element
  } as ExtendedMarkerOptions)
    .setLngLat(marker.coordinates)
    .addTo(map);

  glMarker.getElement().classList.add('map-marker-marker');
  glMarker.getElement().setAttribute('data-map-marker-id', `${marker.id}`);
};

export const clearBuildingLines = (map: Map) => {
  if (!map) return;
  const source = map?.getSource(MapSourceName.SnapToRoadLine);

  if (source) {
    map.removeLayer(MapSourceName.SnapToRoadLine);
    map.removeSource(MapSourceName.SnapToRoadLine);
  }
};

export const clearMapVectors = (map: Map, sourceName: MapSourceName) => {
  if (!map) return;
  const source = map?.getSource(sourceName);

  if (source) {
    map.removeLayer(sourceName);
    map.removeSource(sourceName);
  }
};

interface ISourceData {
  coordinates: LngLatLike[][];
  color?: string;
  id?: string;
  isHovered?: boolean;
}

export interface ILineData extends ISourceData {
  buildingId?: string;
}

export interface IVectorIconData extends ISourceData {
  text?: string;
}

export function drawLines(
  map: Map,
  lineData: ILineData[],
  metaData?: { sourceName?: string; color?: string }
) {
  if (!map) return;
  const sourceName = metaData?.sourceName || MapSourceName.SnapToRoadLine;
  const source = map?.getSource(sourceName) as GeoJSONSource;

  const color = metaData?.color || '#606165A3';
  // Create a line geoJSON.
  const lines = lineData.map((line) => {
    return {
      type: 'Feature',
      properties: {
        color: line.color || color,
        id: line.id,
        opacity: line.isHovered ? 1 : 0.25,
        ...(line.buildingId && {
          buildingId: line.buildingId
        })
      },
      geometry: {
        type: 'LineString',
        coordinates: line.coordinates
      },
      id: line.id
    };
  });

  if (source) {
    source.setData({
      type: 'FeatureCollection',
      features: lines as unknown as Feature[]
    });
  } else {
    map?.addSource(`${sourceName}`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: lines
      }
    });
    map.addLayer({
      id: `${sourceName}`,
      type: 'line',
      source: `${sourceName}`,
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 3,
        'line-dasharray': [1, 3],
        'line-opacity': ['get', 'opacity']
      }
    });
  }
}

export function drawVectorIcons(
  map: Map,
  pointsData: IVectorIconData[],
  metaData: { sourceName: MapSourceName; layerType?: string }
) {
  if (!map) return;
  const sourceName = metaData?.sourceName;
  const source = map?.getSource(sourceName) as GeoJSONSource;

  // Create a points geoJSON.
  const points = pointsData.map((point) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: point.coordinates
      },
      id: `${point.id}`,
      properties: {
        color: point.color,
        id: point.id,
        text: point.text,
        iconSize: point.isHovered ? 0.8 : 0.6,
        textOpacity: point.isHovered ? 1 : 0.6,
        iconOpacity: 1
      }
    } as unknown as unknown;
  });

  if (source) {
    source.setData({
      type: 'FeatureCollection',
      features: points
    });
  } else {
    map?.addSource(sourceName, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: points
      }
    });
    map.addLayer({
      id: sourceName,
      type: 'symbol',
      source: sourceName,
      layout: {
        'icon-size': ['get', 'iconSize'],
        'icon-image': sourceName,
        'text-field': ['get', 'text'],
        'text-size': 12,
        'text-font': ['Open Sans Bold'],
        'text-anchor': 'center',
        'text-offset': [0, -1.6]
      },
      paint: {
        'text-color': '#000',
        'text-halo-width': 2,
        'text-halo-color': 'white',
        'text-opacity': ['get', 'textOpacity'],
        'icon-opacity': ['get', 'iconOpacity'],
        'icon-color': ['get', 'color']
      }
    });
  }
}

const clearVectorIcons = (map: Map, sourceName: MapSourceName) => {
  if (!map) return;
  const source = map?.getSource(sourceName);

  if (source) {
    map.removeLayer(sourceName);
    map.removeSource(sourceName);
  }
};

export const shouldShowMapHouseNumbers = (map: Map, showHouseNumbers: boolean) => {
  if (!map) {
    console.warn('Map is not initialized');
    return;
  }

  if (map.isStyleLoaded() && map.getLayer('housenumbers')) {
    map.setPaintProperty('housenumbers', 'text-opacity', +showHouseNumbers);
  }
};

const displayAddressesAndLocations = ({
  data,
  map,
  enableLocations = true,
  enableSnapToRoad = true,
  hoveredItemId = null,
  dontShowSnapToRoadWhenAddressHasAttachedLocations = false,
  locations
}: {
  data: IBuildingAddressesData[];
  map: Map;
  enableLocations?: boolean;
  enableSnapToRoad?: boolean;
  locations: IFeature[];
  hoveredItemId?: number | null;
  dontShowSnapToRoadWhenAddressHasAttachedLocations?: boolean;
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  // Will remove after adding vector icons for locations
  const snapToRoadLinesData = [] as ILineData[];
  const locationLinesData = [] as ILineData[];
  const addressPointsData = [] as IVectorIconData[];
  const snapToRoadPointsData = [] as IVectorIconData[];
  // If there is not locations it means that FE should draw location markers too (global case)
  const showLocationMarkers = !locations;
  shouldShowMapHouseNumbers(map, false);
  data.forEach((item: IBuildingAddressesData) => {
    const { addresses = [] } = item;

    addresses?.forEach((address: IBuildingAddress) => {
      const { point, snapToRoadCoordinates, featureIds } = address;
      const isAddressHovered = hoveredItemId == address.id;
      // If there is a hoveredItem it should be only one and others should become non-hovered otherwise all items are hovered and there is no any not-hovered item
      const isHovered = hoveredItemId ? isAddressHovered : true;
      if (!point) {
        // Silent error handling for BE until it will updated and all addresses will have coordinates.
        console.error(
          `The address coordinates is missing addressId ${address.id}, buildingId ${item.buildingId}`
        );
        return;
      }
      const { coordinates } = point;
      const startPoint = [coordinates[0], coordinates[1]];

      const addressRelatedLocations = locations?.filter((location) => {
        return featureIds.includes(+location.id);
      });
      // eslint-disable-next-line eqeqeq
      const parking = addressRelatedLocations?.find(
        (location) => location.type == LocationTypeEn.Parking
      );
      const gate = addressRelatedLocations?.find(
        (location) => location.type == LocationTypeEn.Poort
      );
      const entrance = addressRelatedLocations?.find(
        (location) => location.type == LocationTypeEn.Voordeur
      );

      if (enableSnapToRoad || enableLocations) {
        addressPointsData.push({
          coordinates: [startPoint[0], startPoint[1]],
          id: `${address.id}`,
          isHovered,
          color: isAddressHovered ? ADDRESS_HOVERED_ITEM_COLOR : ADDRESS_MARKER_COLOR,
          text: formatAddressHouseNumber(address)
        });
      }
      if (enableLocations && isHovered) {
        if (gate) {
          const locationCoordinates = gate.point.coordinates;

          if (showLocationMarkers) {
            const locationMarker = getMarkerElement(MARKER_TYPE.Poort, GATE_COLOR);
            addMapMarker({
              marker: {
                id: gate.id,
                coordinates: locationCoordinates
              },
              element: locationMarker,
              map
            });
          }
          if (!parking && !entrance) {
            locationLinesData.push({
              coordinates: [startPoint, locationCoordinates],
              id: `${gate?.id}`,
              buildingId: item.buildingId,
              isHovered,
              color: GATE_COLOR
            });
          }
        }

        if (parking) {
          const locationCoordinates = parking.point.coordinates;

          if (showLocationMarkers) {
            const locationMarker = getMarkerElement(MARKER_TYPE.Parking, PARKING_COLOR);
            addMapMarker({
              marker: {
                id: parking.id,
                coordinates: locationCoordinates
              },
              element: locationMarker,
              map
            });
          }
          if (gate) {
            locationLinesData.push({
              coordinates: [locationCoordinates, gate.point.coordinates],
              id: `${gate?.id}`,
              isHovered,
              buildingId: item.buildingId,
              color: GATE_COLOR
            });
          }
          if (!entrance) {
            locationLinesData.push({
              coordinates: [startPoint, locationCoordinates],
              id: `${parking?.id}`,
              buildingId: item.buildingId,
              isHovered,
              color: PARKING_COLOR
            });
          }
        }

        if (entrance) {
          const locationCoordinates = entrance.point.coordinates;

          if (showLocationMarkers) {
            const locationMarker = getMarkerElement(MARKER_TYPE.Voordeur, ENTRANCE_COLOR);
            addMapMarker({
              marker: {
                id: entrance.id,
                coordinates: locationCoordinates
              },
              element: locationMarker,
              map
            });
          }

          if (parking) {
            locationLinesData.push({
              coordinates: [locationCoordinates, parking.point.coordinates],
              id: `${parking?.id}`,
              isHovered,
              buildingId: item.buildingId,
              color: PARKING_COLOR
            });
          } else if (gate) {
            locationLinesData.push({
              coordinates: [locationCoordinates, gate.point.coordinates],
              id: `${gate?.id}`,
              isHovered,
              buildingId: item.buildingId,
              color: GATE_COLOR
            });
          }

          locationLinesData.push({
            coordinates: [locationCoordinates, startPoint],
            id: `${entrance?.id}`,
            isHovered,
            buildingId: item.buildingId,
            color: ENTRANCE_COLOR
          });
        }
      }
      // If it's parking or entrance it should not show snap to road line

      const isAddressHasAttachedLocation = parking || entrance;

      const shouldHideSnapToRoad =
        dontShowSnapToRoadWhenAddressHasAttachedLocations && isAddressHasAttachedLocation;
      const isAllowedToShowSnapToRoad = enableSnapToRoad && !shouldHideSnapToRoad;
      if (isAllowedToShowSnapToRoad && snapToRoadCoordinates && isHovered) {
        const endPoint = [snapToRoadCoordinates.coordinates[0], snapToRoadCoordinates.coordinates[1]];

        snapToRoadLinesData.push({
          coordinates: [startPoint, endPoint],
          id: address.id,
          isHovered,
          color: MAP_SNAP_TO_ROAD_COLOR
        });

        snapToRoadPointsData.push({
          coordinates: [snapToRoadCoordinates.coordinates[0], snapToRoadCoordinates.coordinates[1]],
          id: `${address.id}`,
          isHovered,
          color: MAP_SNAP_TO_ROAD_COLOR
        });
        // Remove snap to road icon if address has attached location
        if (map && map?.getLayer(MapSourceName.SnapToRoadIcon)) {
          map.setFilter(MapSourceName.SnapToRoadIcon, ['!=', 'id', address.id]);
        }
      } else if (!isAllowedToShowSnapToRoad && map?.getLayer(MapSourceName.SnapToRoadIcon)) {
        map.setFilter(MapSourceName.SnapToRoadIcon, ['==', 'id', address.id]);
      }
    });
  });

  if (enableSnapToRoad) {
    // filter out duplicate lines by using coords
    const filteredSnapToRoadLinesData = snapToRoadLinesData;

    drawLines(map, filteredSnapToRoadLinesData, {
      sourceName: MapSourceName.SnapToRoadLine
    });
  } else {
    clearMapVectors(map, MapSourceName.SnapToRoadIcon);
    clearMapVectors(map, MapSourceName.SnapToRoadLine);
  }
  if (enableLocations) {
    drawLines(map, locationLinesData, { sourceName: MapSourceName.LocationLine });
  } else {
    clearMapVectors(map, MapSourceName.LocationLine);
  }

  if (addressPointsData.length > 0) {
    drawVectorIcons(map, addressPointsData, {
      sourceName: MapSourceName.AddressIcon
    });
  }

  if (snapToRoadPointsData.length > 0 && enableSnapToRoad) {
    drawVectorIcons(map, snapToRoadPointsData, {
      sourceName: MapSourceName.SnapToRoadIcon
    });
  }
};

const hoverOverLocations = (hoveredLocations: string[] | null) => {
  const markers = document.querySelectorAll('[data-location-id]');
  const draggableElement = null;
  markers?.forEach((marker) => {
    const notHoveredClassName = 'not-hovered';
    const hoveredLocationItemClassName = 'hovered-location-item';
    const currentMarkerId = `${marker?.getAttribute('data-location-id')}`;
    if (hoveredLocations) {
      if (!hoveredLocations.includes(currentMarkerId)) {
        marker.classList.add(notHoveredClassName);
        marker.classList.remove(hoveredLocationItemClassName);
      } else {
        marker.classList.remove(notHoveredClassName);
        marker.classList.add(hoveredLocationItemClassName);
      }
    } else {
      marker.classList.remove(notHoveredClassName);
      marker.classList.remove(hoveredLocationItemClassName);
    }
  });
  return draggableElement;
};

const restoreAddressesAndLocationsMapState = (map: Map | null) => {
  if (map) {
    hoverOverLocations(null);
    clearTemporaryMarkers();
    clearAllAddressAndLocationMarkers(map);
    clearMapVectors(map, MapSourceName.AddressIcon);
    clearMapVectors(map, MapSourceName.SnapToRoadIcon);
    clearMapVectors(map, MapSourceName.LocationLine);
    clearMapVectors(map, MapSourceName.SnapToRoadLine);
    shouldShowMapHouseNumbers(map, true);
  }
};

export const hoverOverAddress = (addressId: string) => {
  const markers = document.querySelectorAll('[data-map-marker-id]');

  markers?.forEach((marker) => {
    const hoveredMarker = 'hovered-marker';
    if (addressId === marker?.getAttribute('data-map-marker-id')) {
      marker.classList.add(hoveredMarker);
    } else if (marker.classList.contains(hoveredMarker)) {
      marker.classList.remove(hoveredMarker);
    }
  });
};

function addBuildings(items: IBuilding[], map: Map, name?: string) {
  const geoJson = items?.map((item) => ({
    id: `${item.id}`,
    type: 'Feature',
    geometry: item.polygon,
    properties: {
      color: item.color || '#eceff4',
      id: `${item.id}`
    }
  }));

  const sourceName = name || 'buildings';
  const source = map?.getSource(sourceName) as maplibregl.GeoJSONSource;

  if (source) {
    source.setData({
      type: 'FeatureCollection',
      features: geoJson as unknown
    });
    return;
  }
  map?.addSource(`${sourceName}`, {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: geoJson
    }
  });
  map.addLayer({
    id: `${sourceName}-layer`,
    type: 'fill',
    source: sourceName,
    layout: {},
    paint: {
      'fill-color': [
        'case',
        ['boolean', [FEATURE_STATE, BuildingState.MultiSelect], false],
        BUILDING_SELECT_COLOR,
        ['boolean', [FEATURE_STATE, BuildingState.Select], false],
        MAP_SNAP_TO_ROAD_COLOR,
        ['get', 'color'] || BUILDING_COLOR
      ],
      'fill-opacity': ['case', ['boolean', [FEATURE_STATE, BuildingState.Select], false], 0.3, 0.3]
    }
  });

  map.addLayer({
    id: `${sourceName}-borders`,
    type: 'line',
    source: sourceName,
    layout: {},
    paint: {
      'line-color': [
        'case',
        ['boolean', [FEATURE_STATE, BuildingState.MultiSelect], false],
        '#1329fe',
        ['boolean', [FEATURE_STATE, BuildingState.Select], false],
        MAP_SNAP_TO_ROAD_COLOR,
        '#000'
      ],
      'line-width': [
        'case',
        ['boolean', [FEATURE_STATE, BuildingState.MultiSelect], false],
        2,
        ['boolean', [FEATURE_STATE, BuildingState.Select], false],
        2.5,
        1
      ]
    }
  });
}

interface IMapCustomIcon {
  name: MapSourceName;
  url: string;
}

const initializeMapCustomIcons = (map: Map, icons: IMapCustomIcon[]) => {
  map.on('load', () => {
    icons.forEach((icon) => {
      const img = new Image();
      img.src = icon.url;
      img.onload = () => {
        map.addImage(icon.name, img);
      };
    });
  });
};

const transformRequest = (url: string, resourceType: string) => {
  if (isMapboxURL(url)) {
    return transformMapboxUrl(url, resourceType, APP_MAPBOX_MAPS_API_KEY);
  }
  return { url };
};

const getMapBbox = (map: Map) => {
  const bounds = map?.getBounds();

  return `${bounds.getNorthWest().lng},${bounds.getNorthWest().lat},${
    bounds.getSouthEast().lng
  },${bounds.getSouthEast().lat}`;
};

export {
  setBuildingState,
  clearAllMarkers,
  addLocationMarkers,
  addBuildings,
  addDepotMarkers,
  hoverOverLocations,
  clearTemporaryMarkers,
  addMapMarker,
  displayAddressesAndLocations,
  initializeMapCustomIcons,
  restoreAddressesAndLocationsMapState,
  transformRequest,
  getMapBbox,
  clearAllSearchMarkers,
  clearVectorIcons
};
