import { Divider, Grid, useTheme, Tooltip } from '@mui/material';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { IBuildingAddress } from '@types';
import { getMarkerColorByType } from '@utils/map';
import { convertType } from '@helpers/formatLocations';
import { locationsAtom } from '../../../store';

const AddressItemInfo = ({ item }: { item: IBuildingAddress }) => {
  const featureIds = item?.featureIds;
  const [locations] = useAtom(locationsAtom);
  const theme = useTheme();
  const selectedLocations = useMemo(
    () =>
      locations?.filter((location) => {
        return featureIds.includes(location.id);
      }),
    [featureIds, locations]
  );


  const isItemVisible = selectedLocations?.length > 0;

  if (!isItemVisible) {
    return <Grid pb={1} container />;
  }


  return (
    <>
      <Divider sx={{ width: '100%', py: 1 }} />
      <Grid
        container
        sx={{
          background: `${theme.palette.neutral[200]}3A`,
          py: 1,
          px: 2,
          gap: 1
        }}
      >
        {selectedLocations?.map((location) => {
          const currentColor = getMarkerColorByType(convertType(location.type));
          const currentBackgroundColor = `${currentColor}1f`;
          const featureName = location.title;
          return (
            <Grid
              key={location.id}
              sx={{
                p: 1,
                background: currentBackgroundColor,
                color: currentColor,
                fontWeight: 500,
                fontSize: theme.typography.pxToRem(13),
                borderRadius: 1,
                maxWidth: 133,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              <Tooltip title={featureName.length > 18 ? featureName : ''}>{featureName}</Tooltip>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default AddressItemInfo;
