/* DON'T EDIT THIS FILE. IT'S AUTOGENERATED. */
/* istanbul ignore file */
import { createSvgIcon } from '@mui/material/utils';

const IconHighlightAll = createSvgIcon(
  [
    <g fill='currentColor' key='0'>
      <path d='M17 5h-2V3h2v2Zm2 4h2V7h-2v2ZM7 5h2V3H7v2ZM3 17h2v-2H3v2Zm2 4v-2H3c0 1.1.9 2 2 2ZM19 3v2h2c0-1.1-.9-2-2-2Zm-8 2h2V3h-2v2ZM3 9h2V7H3v2Zm4 12h2v-2H7v2Zm-4-8h2v-2H3v2Zm0-8h2V3c-1.1 0-2 .9-2 2Z' />
      <path
        fillRule='evenodd'
        d='m17.8407 16.5161 4.6458-1.6512L10 8l3.6403 13.7764 2.726-4.1084 3.2637 4.1774 1.4744-1.1519-3.2637-4.1774Z'
        clipRule='evenodd'
      />
    </g>,
    <defs key='1' />
  ],
  'IconHighlightAll'
);

export default IconHighlightAll;
