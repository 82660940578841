/* eslint-disable eqeqeq */
import { Grid, Typography, Box } from '@mui/material';
import { FeatureBuilding } from '@types';
import { setBuildingState, BuildingState } from '@utils/map';
import { MapSourceName } from '@utils/map/data';
import useMapContext from '@hooks/useMapContext';
import { SetStateAction, Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Map } from 'maplibre-gl';
import Building from './Building';
import { List } from '../buildings/addresses/BuildingAddressesSection';

const FeatureAttachedBuildings = ({
  buildingsAttachedToFeature,
  setBuildingsAttachedToFeature
}: {
  buildingsAttachedToFeature: FeatureBuilding[] | null;
  setBuildingsAttachedToFeature: Dispatch<SetStateAction<FeatureBuilding[] | null>>;
}) => {
  const [expandedId, setExpandedId] = useState<null | number>(null);
  const [hoveredId, setHoveredId] = useState<null | number>(null);

  const { map } = useMapContext();

  const buildingIds = buildingsAttachedToFeature?.map((building) => building.id);

  useEffect(() => {
    const buildingSource = map?.getSource('buildings');
    const locationsLineDataSource = map?.getSource(MapSourceName.LocationLine);

    if (buildingSource && locationsLineDataSource) {
      const locationLineData = locationsLineDataSource?.serialize()
        .data as GeoJSON.FeatureCollection;

      const clonedLocationLineData = { ...locationLineData };
      buildingIds?.forEach((buildingId) => {
        if ([expandedId, hoveredId].includes(buildingId)) {
          setBuildingState({
            map: map as Map,
            buildingIds: buildingIds || [],
            id: `${buildingId}`,
            property: BuildingState.Select,
            state: true
          });
        } else {
          setBuildingState({
            map: map as Map,
            buildingIds: buildingIds || [],
            id: `${buildingId}`,
            property: BuildingState.Select,
            state: false
          });
        }
      });

      clonedLocationLineData.features = clonedLocationLineData.features.map((feature) => {
        if (
          (expandedId || hoveredId) &&
          ![expandedId, hoveredId].includes(feature?.properties?.buildingId)
        ) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              opacity: 0.5
            }
          };
        }
        return {
          ...feature,
          properties: {
            ...feature.properties,
            opacity: 1
          }
        };
      });

      locationsLineDataSource?.setData(clonedLocationLineData);
    }

    return () => {
      buildingIds?.forEach((buildingId) => {
        setBuildingState({
          map: map as Map,
          buildingIds: buildingIds || [],
          id: `${buildingId}`,
          property: BuildingState.Select,
          state: false
        });
      });
    };
  }, [buildingIds, map, expandedId, hoveredId]);

  const scrollContainer = useRef(null);

  const renderItem = useCallback(
    (index: number, item: FeatureBuilding) => {
      return (
        <Building
          key={item.id}
          identifier={item.id}
          addresses={item.addresses}
          index={index}
          setBuildingsAttachedToFeature={setBuildingsAttachedToFeature}
          setHoveredId={setHoveredId}
          expandedId={expandedId}
          setExpandedId={setExpandedId}
          scrollContainer={scrollContainer}
        />
      );
    },
    [expandedId, setBuildingsAttachedToFeature]
  );

  return (
    <Box pt={1}>
      <Typography
        pb={2}
        sx={{
          fontSize: '1rem',
          fontWeight: 500,
          color: (theme) => theme.palette.neutral.light
        }}
      >
        Gebouwen ({buildingsAttachedToFeature?.length || 0})
      </Typography>
      <Grid
        ref={scrollContainer}
        container
        gap={1}
        sx={{
          width: 400,
          flexDirection: 'column',
        }}
      >
        {buildingsAttachedToFeature?.map((building, index) => renderItem(index, building))}
      </Grid>
    </Box>
  );
};

export default FeatureAttachedBuildings;
