import { Grid, useTheme } from '@mui/material';
import { FeatureBuilding, FeatureBuildingAddress, IBuildingAddress } from '@types';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import formatAddress from '@helpers/formatAddress';
import { ICON_LIGHT } from '@constants';
import BoundedText from '@components/bounded-text';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  draggable,
  dropTargetForElements
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import invariant from 'tiny-invariant';
import { Dispatch, SetStateAction } from 'react';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { createRoot } from 'react-dom/client';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { CustomCard } from './Building';

interface AddressProps {
  address: FeatureBuildingAddress;
  index: number;
  buildingIdentifier: number;
  setBuildingsAttachedToFeature: Dispatch<SetStateAction<FeatureBuilding[] | null>>;
}

type ItemState =
  | { type: 'idle'; closestEdge: null }
  | { type: 'is-over'; closestEdge: Edge | null };

const idleState: ItemState = { type: 'idle', closestEdge: null };

const BuildingAddress = ({
  address,
  index,
  buildingIdentifier,
  setBuildingsAttachedToFeature
}: AddressProps) => {
  const { mainTitle, secondaryTitle } = formatAddress(address as unknown as IBuildingAddress);
  const [isDragging, setIsDragging] = useState(false);
  const [state, setState] = useState<ItemState>(idleState);
  const ref = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  useEffect(() => {
    const el = ref.current;
    invariant(el);

    return combine(
      dropTargetForElements({
        element: el,
        getIsSticky: () => true,
        getData: ({ input, element, source }) => {
          return attachClosestEdge(
            {
              type: 'address',
              id: address.id,
              index
            },
            {
              input,
              element,
              allowedEdges: (source.data.index as number) >= index ? ['top', 'bottom'] : ['bottom']
            }
          );
        },
        onDrop: (args) => {
          const { source, self } = args;
          setBuildingsAttachedToFeature((prevState) => {
            const items = [...(prevState as FeatureBuilding[])] as FeatureBuilding[];
            const buildingIndex = items.findIndex((item) => item.id === Number(buildingIdentifier));
            const [reorderedItem] = items[buildingIndex].addresses.splice(
              source.data.index as number,
              1
            );
            items[buildingIndex].addresses.splice(self.data.index as number, 0, reorderedItem);
            return items;
          });

          setState(idleState);
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          setState((current) => {
            if (current.type === 'is-over' && current.closestEdge === closestEdge) {
              return current;
            }
            return { type: 'is-over', closestEdge };
          });
        },
        onDragLeave() {
          setState(idleState);
        }
      }),
      draggable({
        element: el,
        onDragStart: () => {
          setIsDragging(true);
        },
        canDrag: (args) => {
          const element = args.element as HTMLElement;
          return element.classList.contains('building-address');
        },
        getInitialData: () => {
          return {
            type: 'address',
            id: address.id,
            index
          };
        },
        onDrop: () => setIsDragging(false),
        onGenerateDragPreview: ({ nativeSetDragImage }) => {
          setCustomNativeDragPreview({
            getOffset: pointerOutsideOfPreview({ x: '0px', y: '8px' }),
            render: ({ container }) => {
              const root = createRoot(container);
              root.render(
                <CustomCard
                  isExpanded={false}
                  sx={{
                    width: 300,
                    backgroundColor: theme.palette.primary[100]
                  }}
                  className='building-address'
                >
                  <Grid
                    container
                    flexDirection='row'
                    flexWrap='nowrap'
                    alignItems='center'
                    gap={1}
                    mx={2}
                  >
                    <Grid item display='flex'>
                      <LocationOnRoundedIcon
                        sx={{
                          color: ICON_LIGHT,
                          fontSize: '24px'
                        }}
                      />
                    </Grid>
                    <Grid container item flexDirection='column'>
                      <BoundedText
                        initialWidth={259}
                        text={mainTitle}
                        textSx={{
                          color: theme.palette.neutral[900],
                          fontSize: theme.typography.pxToRem(14)
                        }}
                      >
                        {mainTitle}
                      </BoundedText>

                      {/* if it's default string otherwise it can be ReactElement */}
                      {typeof secondaryTitle === 'string' ? (
                        <BoundedText
                          initialWidth={259}
                          text={secondaryTitle}
                          textSx={{
                            color: theme.palette.neutral[600],
                            fontSize: theme.typography.pxToRem(13)
                          }}
                        >
                          {secondaryTitle}
                        </BoundedText>
                      ) : (
                        secondaryTitle
                      )}
                    </Grid>
                  </Grid>
                </CustomCard>
              );
              return () => {
                root.unmount();
              };
            },
            nativeSetDragImage
          });
        }
      })
    );
  }, [
    address.id,
    buildingIdentifier,
    index,
    mainTitle,
    secondaryTitle,
    setBuildingsAttachedToFeature,
    theme.palette.neutral,
    theme.palette.primary,
    theme.typography
  ]);

  return (
    <CustomCard
      ref={ref}
      isExpanded={false}
      sx={{
        backgroundColor: '#F9F9F9',
        '&:hover': {
          backgroundColor: '#F9F9F9'
        },
        position: 'relative',
        cursor: 'grab',
        ...(isDragging
          ? { backgroundColor: theme.palette.primary[100], opacity: 0.5, cursor: 'move' }
          : {}),
        ...(state?.closestEdge && {
          ':before': {
            content: '""',
            position: 'absolute',
            top: state?.closestEdge === 'top' ? -8 : 54,
            left: -8,
            right: 0,
            borderRadius: '50%',
            height: 8,
            width: 8,
            border: `2px solid ${theme.palette.primary.main}`
          },
          ':after': {
            content: '""',
            position: 'absolute',
            ...(state?.closestEdge === 'top' ? { top: -4 } : { bottom: -4 }),
            left: 0,
            right: 0,
            borderBottom: `2px solid ${theme.palette.primary.main}`
          }
        })
      }}
      className='building-address'
    >
      <Grid container flexDirection='row' flexWrap='nowrap' alignItems='center' gap={1} mx={2}>
        <Grid item display='flex'>
          <LocationOnRoundedIcon
            sx={{
              color: ICON_LIGHT,
              fontSize: '24px'
            }}
          />
        </Grid>
        <Grid container item flexDirection='column'>
          <BoundedText
            initialWidth={259}
            text={mainTitle}
            textSx={{
              color: theme.palette.neutral[900],
              fontSize: theme.typography.pxToRem(14)
            }}
          >
            {mainTitle}
          </BoundedText>

          {/* if it's default string otherwise it can be ReactElement */}
          {typeof secondaryTitle === 'string' ? (
            <BoundedText
              initialWidth={259}
              text={secondaryTitle}
              textSx={{
                color: theme.palette.neutral[600],
                fontSize: theme.typography.pxToRem(13)
              }}
            >
              {secondaryTitle}
            </BoundedText>
          ) : (
            secondaryTitle
          )}
        </Grid>
      </Grid>
    </CustomCard>
  );
};

export default BuildingAddress;
