import { Grid, SxProps, Typography } from '@mui/material';

const SearchPlaceholder = ({
  isSearching,
  searchValue,
  context,
  sx
}: {
  isSearching: boolean;
  searchValue: string;
  context: string;
  sx?: SxProps;
}) => (
  <Grid
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Typography
      variant='body2'
      sx={{
        color: (theme) => theme.palette.text.landingGrey,
        py: 4,
        ...sx
      }}
    >
      {isSearching ? `Zoeken naar ${context}...` : `Geen ${context} gevonden voor “${searchValue}”`}
    </Typography>
  </Grid>
);

export default SearchPlaceholder;
