/* eslint-disable no-underscore-dangle */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable consistent-return */
import { useContext, useEffect, useState } from 'react';
import maplibregl, { LngLat, Map, Marker } from 'maplibre-gl';
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Divider
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import MapContext from '@contexts/MapContext';
import { getMapBbox } from '@utils/map';
import { batchCreateLocation, createLocation, getLocations } from '@services/buildingService';
import PageHeader, { PAGE_HEADER_BUTTON_TYPE } from '@components/PageHeader';
import { COMMON_ERROR_MESSAGE, LocationType } from '@constants';
import { convertType } from '@helpers/formatLocations';
import { ICoordinate, IFeature } from '@types';
import ButtonWithLoading from '@components/ui-kit/ButtonWithLoading';
import {
  isBuildingSelectModeAtom,
  isLocationsLoadingAtom,
  locationsAtom,
  selectedBuildingsAtom
} from '@store';
import useSnackbar from '@hooks/use-snackbar';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import useChangeRouterSearchParams from '@hooks/useChangeRouterSearchParams';
import PickLocationButton from './PickLocationButton';
import CypressIds from '../../../../cypressIds';

interface IBuildingLocationFormError {
  value: string;
  fieldName: string;
}

const LocationCreatePage = () => {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<IBuildingLocationFormError | null>(null);
  const { map, buildings } = useContext(MapContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const [type, setType] = useState<LocationType>('');
  const [name, setName] = useState<string>('');
  const [coordinates, setCoordinates] = useState<ICoordinate>([0, 0]);
  const [marker, setMarker] = useState<Marker | null>(null);
  const [isCreatingLocation, setIsCreatingLocation] = useState<boolean>(false);
  const [locations, setLocations] = useAtom(locationsAtom);
  const [isLocationsLoading, setIsLocationsLoading] = useAtom(isLocationsLoadingAtom);
  const selectedBuildingsFromWindow = searchParams.get('selectedBuildings')?.split(',');
  const currentLocation = searchParams.get('currentLocation');
  const previousLocation = useRef('');
  const [isBuildingSelectMode, setIsBuildingSelectMode] = useAtom(isBuildingSelectModeAtom);
  const [selectedBuildings, setSelectedBuildings] = useAtom(selectedBuildingsAtom);
  const changeRouterParams = useChangeRouterSearchParams();
  const snackbar = useSnackbar();
  const navigate = useAdvancedNavigate();

  const fetchLocations = useCallback(async () => {
    setIsLocationsLoading(true);
    const bbox = getMapBbox(map as Map);
    getLocations(bbox)
      .then((features) => {
        setLocations(features);
      })
      .finally(() => {
        setIsLocationsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (map && currentLocation?.length > 0 && currentLocation !== previousLocation.current) {
      const [lat, lng] = currentLocation?.split(',');

      const newMarker = new maplibregl.Marker({
        color: '#202124',
        draggable: true
      })
        .setLngLat([+lng, +lat])
        .addTo(map);

      setCoordinates([+lng, +lat]);
      setMarker(newMarker);
      changeRouterParams('currentLocation');
      previousLocation.current = currentLocation;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, map]);

  useEffect(() => {
    if (!map && window.location.pathname.includes('batch')) {
      // Batch create locations
      if (
        !selectedBuildingsFromWindow ||
        (!isBuildingSelectMode && isBuildingSelectMode !== null)
      ) {
        navigate(`/`);
      } else if (!isBuildingSelectMode && selectedBuildingsFromWindow?.length > 0) {
        setIsBuildingSelectMode(true);
      }
    }
  }, [
    buildings,
    isBuildingSelectMode,
    map,
    navigate,
    selectedBuildingsFromWindow,
    setIsBuildingSelectMode
  ]);

  useEffect(() => {
    if (!locations && map && !isLocationsLoading) {
      fetchLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, map]);

  useEffect(
    () => () => {
      marker?.remove();
    },
    [marker, isEditMode]
  );

  const handleBack = () => {
    changeRouterParams('selectedBuildings');
    setSelectedBuildings([]);
    setIsBuildingSelectMode(false);
    navigate(`/`);
  };

  const handleSave = () => {
    if (!name) {
      return setError({
        value: 'Naam is verplicht!',
        fieldName: 'name'
      });
    }

    if (!type) {
      return setError({
        value: 'Type is verplicht!',
        fieldName: 'type'
      });
    }
    if (!coordinates || coordinates.length !== 2 || coordinates[0] === 0 || coordinates[1] === 0) {
      return setError({
        value: 'Coördinatenveld is verplicht!',
        fieldName: 'coordinates'
      });
    }

    if (selectedBuildings && selectedBuildings?.length > 0) {
      setIsCreatingLocation(true);
      batchCreateLocation({
        feature: {
          point: {
            type: 'Point',
            coordinates
          },
          type: convertType(type),
          title: name
        },
        buildingIds: selectedBuildings.map((id) => parseInt(id, 10)) as number[]
      })
        .then(() => {
          snackbar.show('Locaties succesvol toegevoegd.');
          setIsBuildingSelectMode(false);
          setSelectedBuildings(null);
          fetchLocations();
          handleBack();
        })
        .catch(() => {
          snackbar.error(COMMON_ERROR_MESSAGE);
        })
        .finally(() => {
          setIsCreatingLocation(false);
        });
    } else {
      setIsCreatingLocation(true);
      const payload = {
        point: {
          type: 'Point',
          coordinates
        },
        type: convertType(type),
        title: name,
        buildings: []
      } as unknown as IFeature;
      createLocation(payload)
        .then(() => {
          snackbar.show('Locatie succesvol toegevoegd.');
          fetchLocations();
          handleBack();
        })
        .catch(() => {
          snackbar.error(COMMON_ERROR_MESSAGE);
        })
        .finally(() => {
          setIsCreatingLocation(false);
          setIsBuildingSelectMode(false);
        });
    }
  };

  useEffect(() => {
    const handleMapMouseMove = (e: {
      originalEvent: { clientX: number };
      point: { y: number };
      lngLat: { lng: number; lat: number };
    }) => {
      if (isEditMode) {
        if (marker) {
          marker.remove();
        }
        const coordsElement = document?.getElementById('coordinates') as HTMLElement;
        if (map) {
          map.getCanvas().style.cursor = 'crosshair';
          coordsElement.style.display = 'block';
          if (e.originalEvent.clientX < 450) {
            coordsElement.style.display = 'none';
            return;
          }
          coordsElement.style.top = `${e.point.y - 15}px`;
          coordsElement.style.left = `${e.originalEvent.clientX - 230}px`;
          coordsElement.innerHTML = `${e.lngLat.lng.toFixed(7)}, ${e.lngLat.lat.toFixed(7)}`;
        }
      }
    };

    const handleMapClick = (e: { lngLat: LngLat }) => {
      if (isEditMode && map) {
        const coordsElement = document.getElementById('coordinates');
        const { lng, lat } = e.lngLat;
        const marker = new maplibregl.Marker({
          color: '#202124',
          draggable: true
        })
          .setLngLat([+lng.toFixed(7), +lat.toFixed(7)])
          .addTo(map);
        setCoordinates([+lng.toFixed(7), +lat.toFixed(7)]);
        setMarker(marker);
        map.getCanvas().style.cursor = 'grab';
        if (coordsElement) {
          coordsElement.style.display = 'none';
        }

        setIsEditMode(false);
      }
    };

    map?.on('mousemove', handleMapMouseMove);
    map?.on('click', handleMapClick);
    if (marker) {
      const coordsElement = document.getElementById('coordinates') as HTMLElement;
      marker.on('drag', (e) => {
        if (map) {
          coordsElement.style.display = 'block';
          const { x, y } = e.target._pos;
          const { lng, lat } = e.target._lngLat;
          coordsElement.style.top = `${y - 15}px`;
          coordsElement.style.left = `${x + 230}px`;
          coordsElement.innerHTML = `${lng.toFixed(7)}, ${lat.toFixed(7)}`;
          setCoordinates([parseFloat(lng.toFixed(7)), parseFloat(lat.toFixed(7))]);
        }
      });

      marker.on('dragend', () => {
        coordsElement.style.display = 'none';
        const { lng, lat } = marker.getLngLat();
        setCoordinates([parseFloat(lng.toFixed(7)), parseFloat(lat.toFixed(7))]);
      });
      setMarker(marker);
    }

    return () => {
      if (map) {
        const coordsElement = document.getElementById('coordinates') as HTMLElement;
        if (coordsElement) {
          coordsElement.style.display = 'none';
        }
        map.getCanvas().style.cursor = 'grab';
        map.off('mousemove', handleMapMouseMove);
        map.off('click', handleMapClick);
      }
    };
  }, [marker, isEditMode, map]);

  const handlePickLocationClick = () => {
    setIsEditMode((prevState) => !prevState);
  };

  return (
    <>
      <PageHeader
        buttonType={PAGE_HEADER_BUTTON_TYPE.CLOSE}
        onClick={handleBack}
        label='Locatie toevoegen'
      />

      <Grid display='flex' flexDirection='column' gap={3} pt={3} height='100%'>
        <Grid px={3}>
          <FormControl fullWidth>
            <TextField
              data-cy={CypressIds.LOCATION_NAME_INPUT}
              label='Naam'
              {...(error?.fieldName === 'name' && {
                error: true,
                helperText: error?.value
              })}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </FormControl>
        </Grid>
        <Grid px={3}>
          <FormControl fullWidth>
            <Autocomplete
              onChange={(e, value: string | null) => setType(value as LocationType)}
              options={Object.values(LocationType)}
              value={type}
              renderOption={(props, option) => {
                return <MenuItem {...props}>{option}</MenuItem>;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Type'
                  data-cy={CypressIds.LOCATION_TYPE_SELECT}
                  {...(error?.fieldName === 'type' && {
                    error: true,
                    helperText: error?.value
                  })}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid px={3}>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <TextField
              onChange={(e) =>
                setCoordinates(e.target.value.split(',').map((value) => Number(value)))
              }
              value={`${coordinates[0]}, ${coordinates[1]}`}
              sx={{
                '& fieldset': {
                  border: '1px solid #e2e8f0',
                  borderRadius: 0,
                  borderTopLeftRadius: 4,
                  borderBottomLeftRadius: 4
                },
                pointerEvents: 'none'
              }}
              label='Coördinaten'
              {...(error?.fieldName === 'coordinates' &&
                !marker && {
                  error: true,
                  helperText: error?.value
                })}
            />
            <PickLocationButton
              data-cy={CypressIds.PICK_LOCATION_BUTTON}
              onClick={handlePickLocationClick}
            />
          </Grid>
          {marker && (
            <Grid
              sx={{
                mt: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'text.secondary'
              }}
            >
              <InfoOutlined />
              <Typography variant='caption'>
                U kunt de markering slepen om de locatie te wijzigen
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider flexItem />
      <Grid>
        <Grid display='flex' justifyContent='flex-end' p={3} gap={1}>
          <Button
            type='button'
            onClick={handleBack}
            variant='text'
            sx={{
              fontSize: '0.9375rem',
              color: (theme) => theme.palette.neutral.light,
              fontWeight: 500
            }}
          >
            Annuleren
          </Button>
          <ButtonWithLoading
            loading={isCreatingLocation}
            type='button'
            dataCy={CypressIds.COMMON_CONFIRM_BUTTON}
            onClick={handleSave}
            variant='contained'
          >
            Opslaan
          </ButtonWithLoading>
        </Grid>
      </Grid>
    </>
  );
};

export default LocationCreatePage;
